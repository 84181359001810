<template >
  <div :class="className" :style="style" ref="lavContainer" v-on:click="hit"></div>
</template>

<script>
import lottie from "lottie-web-light";
// const lottie = () => import("lottie-web-light");

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    height: String,
    width: String,
    type: String,
    event: Function,
    className: String,
    value: String,
    loop: Boolean,
    frame: Number,
    dynamic: Boolean,
    state: Number,
    states: Number,
    ratio: String,
  },
  watch: {
    frame: function () {
      let current = this.frame - this.$el.getBoundingClientRect().top;
      if (current < 0) current = 0;
      this.anim.goToAndStop(current, false);
    },
  },
  data() {
    return {
      style: {
        width: this.width ? `${this.width}px` : "100%",
        height: this.height ? `${this.height}px` : "100%",
        overflow: "hidden",
        margin: "0 auto",
        type: this.type ? this.type : "fullscreen",
        event: this.event,
        className: this.className ? this.className : "",
        value: this.value ? this.value : 0,
      },
    };
  },

  mounted() {
    this.style.width = window.innerWidth;
    this.style.height = window.innerHeight;
    this.anim = lottie.loadAnimation({
      container: this.$refs.lavContainer,
      renderer: "svg",
      loop: this.loop ? this.loop : this.options.loop !== false,
      autoplay: this.options.autoplay !== false,
      animationData: this.options.animationData,
      // rendererSettings: this.options.rendererSettings,
      rendererSettings: {
        preserveAspectRatio: this.ratio ? this.ratio : "xMidYMid slice", // Supports the same options as the svg element's preserveAspectRatio property
        clearCanvas: false,
        progressiveLoad: false, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
        hideOnTransparent: true, //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
        className: "some-css-class-name",
        id: "some-id",
      },
    });
    this.anim.onEnterFrame = this.enterFrame;
    this.$emit("animCreated", this.anim);
  },

  methods: {
    goToAndStop: function (frame) {
      this.anim.goToAndStop(frame, false);
    },
    hit: function () {
      if (this.dynamic) {
        if (
          Math.round(this.anim.currentFrame) >=
          Math.round(this.anim.totalFrames) - 1
        ) {
          this.anim.goToAndStop(0);
        }
        this.anim.play();
      }
      this.$emit("hitted", this.value);
    },
    enterFrame: function () {
      if (this.states && this.states > 0 && this.dynamic) {
        if (
          Math.floor(this.anim.currentFrame) ===
          Math.ceil(this.anim.totalFrames / this.states) - 1
        ) {
          // this.anim.pause();
          setTimeout(
            function () {
              this.anim.pause();
            }.bind(this),
            20
          );
        }
      }
    },
  },
};
</script>